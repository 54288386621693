<template>
  <div>
    <card-information-filter  @active-change="filterActive = $event" :filter-model="filterModel" />
    <card-information-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import CardInformationFilter from '@/views/Nsi/CardInformation/CardInformationFilter';
import CardInformationTable from '@/views/Nsi/CardInformation/CardInformationTable';

export default {
  name: 'CardInformation',
  components: { CardInformationTable, CardInformationFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        parentNameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
