<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Дополнительная информация
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
        <SaveIcon /> Сохранить
      </el-button>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="cardInformationForm" :model="cardInformation" label-width="30rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="cardInformation.name"></el-input>
      </el-form-item>
      <el-form-item prop="isDisabled" label="Запрет добавления:">
        <el-checkbox
          v-model="cardInformation.isDisabled"
        >
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="usedInCompare" label="Используется при сопоставлении событий:">
        <el-checkbox
          v-model="cardInformation.usedInCompare"
        >
        </el-checkbox>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import SaveIcon from '@/assets/icons/save.svg';
import cardInformationApi from '@/api/nsi/cardInformation';

export default {
  name: 'CardInformationModal',
  props: ['value', 'cardInformation'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addCardInformation(cardInformation) {
      await cardInformationApi.addCardInformation(cardInformation);
      this.$emit('on-create');
    },
    async updateCardInformation(cardInformation) {
      await cardInformationApi.updateCardInformation(cardInformation);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.cardInformationForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      const request = this.cardInformation.id ? this.updateCardInformation : this.addCardInformation;
      this.loading = true;
      await request(this.cardInformation);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
